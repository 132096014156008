import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useBreakpointDown } from 'qualification/hooks/useBreakpoints';

const QUESTIONS = [
  { question: 'q1.question', answer: 'q1.answer' },
  { question: 'q2.question', answer: 'q2.answer' },
  { question: 'q3.question', answer: 'q3.answer' },
  { question: 'q4.question', answer: 'q4.answer' },
  { question: 'q5.question', answer: 'q5.answer' },
  { question: 'q6.question', answer: 'q6.answer' },
  { question: 'q7.question', answer: 'q7.answer' },
  { question: 'q8.question', answer: 'q8.answer' },
  { question: 'q9.question', answer: 'q9.answer' },
  { question: 'q10.question', answer: 'q10.answer' },
  { question: 'q11.question', answer: 'q11.answer' },
  { question: 'q12.question', answer: 'q12.answer' },
];

export default function FAQ() {
  const { t } = useTranslation('homepage');
  const mobile = useBreakpointDown('sm');

  return (
    <Wrapper>
      <Content>
        <Title mobile={mobile}>{t('faq.title')}</Title>
        {QUESTIONS.map(({ question, answer }, idx) => {
          return (
            <StyledAccordion key={idx}>
              <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <QuestionTypography>{t(`faq.${question}`)}</QuestionTypography>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <AnswerTypography>
                  <Trans
                    i18nKey={`homepage:faq.${answer}`}
                    components={{
                      listItem: <li />,
                      numberedList: <ol />,
                      bulletList: <ul />,
                    }}
                  />
                </AnswerTypography>
              </StyledAccordionDetails>
            </StyledAccordion>
          );
        })}
      </Content>
    </Wrapper>
  );
}

const StyledAccordionDetails = styled(AccordionDetails)`
  padding-top: 0;
`;

const Title = styled(Typography)`
  text-align: center;
  font-family: Poppins;
  font-size: ${(props) => props.theme.spacing(4.5)}px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: ${(props) => (props.mobile ? '32px' : '48px')};
`;

const StyledAccordion = styled(Accordion)`
  background: #f8f8f8;
  margin: ${(props) => props.theme.spacing(2, 0)};
  border-radius: ${(props) => props.theme.spacing(1)}px;
  box-shadow: none;
  &:before {
    opacity: 0 !important;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  width: 100%;
  padding: ${(props) => props.theme.spacing(0, 2)};
  margin: 0 !important;
`;

const QuestionTypography = styled(Typography)`
  font-family: Poppins;
  font-size: ${(props) => props.theme.spacing(2)}px;
  font-style: normal;
  font-weight: 700;
  line-height: 244%;
  width: 100%;
`;

const AnswerTypography = styled(Typography)`
  font-family: Poppins;
  font-size: ${(props) => props.theme.spacing(2)}px;
  font-style: normal;
  font-weight: 400;
  line-height: 168%;
`;

const Wrapper = styled(Box)`
  font-family: 'Playfair Display';
  font-style: normal;
  padding: 0 16px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0;
  }
`;

const Content = styled(Box)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: fill-available !important;
    max-width: 1050px !important;
    margin: auto;
  }
`;
